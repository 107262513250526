<template>
  <div>

    <b-row>
    <div v-if="fetchData.length==0">
   <span class="font-weight-bold text-body-heading m-5"
                > No Any Position Records Found</span
              >
    </div>
      <b-col md="3" v-for="(listItem, index) in fetchData" :key="index">
        <b-card class="card-app-design ">
          <div class="d-flex">
          <div  class="d-flex justify-content-start align-items-center">
            <b-badge class="h-20 " variant="light-primary">
              {{ dateFormat(listItem.beginDateUtc) }}
            </b-badge>
            </div>
            <div
              class="d-flex justify-content-end align-items-center w-100"
              v-if="listItem.endDateUtc"
            >
              <span class="font-weight-bold text-body-heading mr-1"
                >{{ dateFormatDay(listItem.endDateUtc) }} Days to Close</span
              >
              <vue-apex-charts
                type="radialBar"
                height="30"
                width="30"
                :options="chartData[0].options"
                :series="[listItem.percent]"
              />
            </div>
          </div>
          <b-card-title class="mt-1 mb-75">
            {{ listItem.title }}
          </b-card-title>
          <b-card-text class="font-small-2 mb-1 min-h-40">
            {{ cutString(listItem.description, 80) }}
          </b-card-text>

          <!-- design group -->
          <div class="m-0">
            <h6 class="section-label">Shortcuts</h6>
            <b-button
              variant="text"
              class="btn-icon ml-0"
              :to="{
                name: 'apps-findcondidate',
                params: { id: listItem.id },
              }"
            >
              <b-badge variant="light-warning" class="mr-5">
                Add Candidate
              </b-badge></b-button
            >
          </div>
          <div class="mb-1">
            <h6 class="section-label mt-1">Recruiters</h6>
            <b-avatar-group  class="mt-0  min-h-35" size="33">
              <b-avatar
              v-for="avatar in listItem.users"
              :key="avatar.avatarFileUrl"
              size="30"
               v-b-tooltip.hover.top="avatar.userFullName"
              :src="avatar.avatarFileUrl"
              class="pull-up"
            />
            </b-avatar-group>
          </div>
          <!--/ design group -->

          <div class="d-flex">
            <div class="col-md-6">
              <p class="card-text mb-0" style="text-align:center !important">Candidate</p>
              <h6 class="mb-0" style="text-align:center !important">{{ listItem.total }}</h6>
            </div>
            <div class="col-md-6">
              <p class="card-text mb-0" style="text-align:center !important">Match for Position</p>
              <h6 class="mb-0" style="text-align:center !important">{{ listItem.match }}</h6>
            </div>
          </div>

          <!-- button -->
          <b-button
            class="m-0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            block
            :to="{ name: 'companies-candidate', params: { id: listItem.id } }"
          >
            Show Details
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BTable,
  VBModal,
  BBadge,
  BCard,
  BCardTitle,
  BCardText,
  BAvatar,
  BAvatarGroup,
  VBTooltip
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

moment.locale('tr');
const $trackBgColor = "#e9ecef";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BBadge,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BTable,
    VBModal,
    BCard,
    BCardTitle,
    BCardText,
    BAvatar,
    VueApexCharts,
    BAvatarGroup,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartData: [],
      chartClone: {},
      chartColor: [
        $themeColors.primary,
        $themeColors.warning,
        $themeColors.secondary,
        $themeColors.info,
        $themeColors.danger,
      ],
      chartSeries: [54.4, 6.1, 14.6, 4.2, 8],
      browserData: [
        {
          browserImg: require("@/assets/images/icons/google-chrome.png"),
          name: "Google Chrome",
          usage: "54.4%",
        },
        {
          browserImg: require("@/assets/images/icons/mozila-firefox.png"),
          name: "Mozila Firefox",
          usage: "6.1%",
        },
        {
          browserImg: require("@/assets/images/icons/apple-safari.png"),
          name: "Apple Safari",
          usage: "14.6%",
        },
        {
          browserImg: require("@/assets/images/icons/internet-explorer.png"),
          name: "Internet Explorer",
          usage: "4.2%",
        },
        {
          browserImg: require("@/assets/images/icons/opera.png"),
          name: "Opera Mini",
          usage: "8.%",
        },
      ],
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "22%",
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
    };
  },
  methods: {
    dateFormat(date) {
      return moment(String(date))
        ? moment(String(date)).format("DD-MMM-YYYY")
        : date;
    },
    dateFormatDay(nextDay) {
      let day = 0;
      if(nextDay && nextDay!= null)
      {
        day = moment(nextDay).diff(moment(new Date()), "days");
      }
       
      return day>0 ?day : 0;
    },
    modalId(id) {
      return "modal" + id;
    },
    cutString(content, length) {
      let clamp = "...";
      return content.length > length
        ? content.slice(0, length) + clamp
        : content;
    },
  },
  created() {
    for (let i = 0; i < this.browserData.length; i += 1) {
      const chartClone = JSON.parse(JSON.stringify(this.chart));
      chartClone.options.colors[0] = this.chartColor[4];
      chartClone.series[0] = this.chartSeries[i];
      this.chartData.push(chartClone);
    }
  },
  setup(props) {
    const fetchData = ref([]);

    const tableColumns = [
      { key: "title", label: "Title", sortable: false },
      { key: "description", label: "Description", sortable: false },
      { key: "isActive", label: "status", sortable: false },
      { key: "actions", sortable: false },
    ];

    const resolveUserStatusVariant = (isActive) => {
      if (isActive === false) return "success";
      if (isActive === true) return "danger";
      return "primary";
    };

    store
      .dispatch("company/fetchJobs", props.userData.id)
      .then((response) => {
        const data = response.data.data.filter((x) => x.isActive === false).sort((a,b) => (a.percent> b.percent)? -1 : ((b.percent> a.percent)? 1 : 0));
        fetchData.value = data;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Job list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    return {
      fetchData,
      tableColumns,
      resolveUserStatusVariant,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.h-20 {
  height: 20px;
}
.min-h-35{
min-height: 35px;
}
.min-h-40{
min-height: 40px;
}
</style>
