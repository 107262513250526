<template>
  <div>

    <!-- Header: Personal Info -->
    <div class="d-flex">
    
      <h4 class="mb-2 ml-50">
       {{userData.name}} Job List
      </h4>
    </div>
     <b-table
        
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
  
    <template #cell(isActive)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.isActive)}`"
            class="text-capitalize"
          >
            {{ data.item.isActive?"Archive":"Active" }}
          </b-badge>
        </template>

     
      </b-table>

  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup,
   BFormInput, BFormRadioGroup, BFormCheckboxGroup,
    BButton,BTable,VBModal,BBadge,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow, BCol, BForm,BBadge,
     BFormGroup, flatPickr, BFormInput,
      vSelect, BFormRadioGroup,
       BFormCheckboxGroup, BButton,BTable,
       VBModal,
  },
   props: {
    userData: {
      type: Object,
      required: true,
    },
  },
    methods: {
     modalId(id){
    return 'modal' + id
    },

  },
  setup(props) {
  const tableColumns = [
    { key: 'title',label: 'Title', sortable: false },
    { key: 'description',label: 'Description', sortable: false },
    { key: 'isActive',label: 'status', sortable: false },
  ]

    const resolveUserStatusVariant = isActive => {
    if (isActive === false) return 'success'
    if (isActive === true) return 'danger'
    return 'primary'
  }

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('company/fetchJobs',props.userData.id)
      .then(response => {
       
        const  data  = response.data.data.filter(x=>x.isActive===true)
        callback(data)

      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Job list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

    return {
      tableColumns,
      fetchUsers,
      resolveUserStatusVariant
     
     
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
