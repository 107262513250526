<template>
  <component :is="userData === undefined ? 'div' : 'div'">
    <b-tabs v-if="userData" >
      <b-tab active>
        <template #title>
          <span class="d-none d-sm-inline">Active Jobs</span>
        </template>
        <user-edit-tab-information
          :user-data="userData"
          :method="onSubmit"
          class="mt-2 pt-75"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <span class="d-none d-sm-inline">Archive</span>
        </template>
         <b-card >
        <company-edit-tab-archive
          :user-data="userData"
          :method="onSubmit"
          class="mt-2 pt-75"
        />
          </b-card>
      </b-tab>
      <b-tab >
      
        <template #title>
          <span class="d-none d-sm-inline">Details</span>
        </template>
         <b-card >
        <user-edit-tab-account :user-data="userData" class="mt-2 pt-75" />
        </b-card>
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import userStoreModule from "../companyStoreModule";
import UserEditTabAccount from "./CompanyEditTabAccount.vue";
import CompanyEditTabArchive from "./CompanyEditTabArchive.vue";
import UserEditTabInformation from "./CompanyEditTabInformation.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ToastificationContent,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    UserEditTabAccount,
    UserEditTabInformation,
    CompanyEditTabArchive,
  },
  setup() {
    const userData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "company";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("company/fetchCompanyId", { id: router.currentRoute.params.id })
      .then((response) => {
        userData.value = response.data.data;
         store.commit("user/UPDATE_COMPAMY", response.data.data.name);
      })
      .catch((error) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.messages[0].messageText,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    return {
      userData,
    };
  },
};
</script>

<style>
</style>
